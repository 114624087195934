/********** Template CSS **********/
@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600&family=Nunito:wght@600;700;800&family=Pacifico&display=swap");

@font-face {
  font-family: "arabicFont";
  src: url("../public/assets/fonts/AlHurraTxtBold.ttf") format("truetype");
  font-style: normal;
}

[dir="rtl"] * {
  font-family: "arabicFont", serif;
}

:root {
  --primary: #c38957;
  --light: #ffffff;
  --dark: #0c0c0c;
}

.ff-secondary {
  font-family: "Pacifico", cursive;
}

.fw-medium {
  font-weight: 600 !important;
}

.fw-semi-bold {
  font-weight: 700 !important;
}

.back-to-top {
  position: fixed;
  display: none;
  right: 45px;
  bottom: 45px;
  z-index: 99;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1;
}

/*** Button ***/
.btn {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  transition: 0.5s;
}

.btn.btn-primary,
.btn.btn-secondary {
  color: #ffffff;
}

.btn-square {
  width: 38px;
  height: 38px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  border-radius: 10px;
}

/*** Navbar ***/
.navbar-dark .navbar-nav .nav-link {
  position: relative;
  margin-left: 25px;
  padding: 35px 0;
  font-size: 15px;
  color: var(--light) !important;
  text-transform: uppercase;
  font-weight: 500;
  outline: none;
  transition: 0.5s;
}

.sticky-top.navbar-dark .navbar-nav .nav-link {
  padding: 20px 0;
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link.active {
  color: var(--primary) !important;
}

.navbar-dark .navbar-brand img {
  max-height: 80px;
  transition: 0.5s;
}

.sticky-top.navbar-dark .navbar-brand img {
  max-height: 45px;
}

@media (max-width: 991.98px) {
  .sticky-top.navbar-dark {
    position: relative;
  }

  .navbar-dark .navbar-collapse {
    margin-top: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  .navbar-dark .navbar-nav .nav-link,
  .sticky-top.navbar-dark .navbar-nav .nav-link {
    padding: 10px 0;
    margin-left: 0;
  }

  .navbar-dark .navbar-brand img {
    max-height: 45px;
  }
}

/* @media (min-width: 992px) { */
.navbar-dark {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  background: black !important;
}

.sticky-top.navbar-dark {
  position: fixed;
  background: var(--dark) !important;
}
/* } */

/*** Hero Header ***/
.hero-header {
  background: linear-gradient(rgba(0, 0, 0, 0.9), rgba(47, 48, 50, 0.9)),
    url(../public/assets/img/background.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-header img {
  animation: imgRotate 100s linear infinite;
}

@keyframes imgRotate {
  100% {
    transform: rotate(360deg);
  }
}

.breadcrumb-item + .breadcrumb-item::before {
  color: rgba(255, 255, 255, 0.5);
}

/*** Section Title ***/
.section-title {
  position: relative;
  display: inline-block;
}

[dir="ltr"] .section-title::before {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  left: -55px;
  margin-top: -1px;
  background: var(--primary);
}

[dir="ltr"] .section-title::after {
  position: absolute;
  content: "";
  width: 45px;
  height: 2px;
  top: 50%;
  right: -55px;
  margin-top: -1px;
  background: var(--primary);
}

[dir="ltr"] .section-title.text-start::before,
[dir="ltr"] .section-title.text-end::after {
  display: none;
}

[dir="rtl"] .section-title::before {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  top: 0%;
  right: 0px; /* Flipped for RTL */
  margin-top: 0px;
  background: var(--primary);
}

[dir="rtl"] .section-title::after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  top: 0%;
  right: 0px; /* Flipped for RTL */
  margin-top: 0px;
  background: var(--primary);
}

[dir="rtl"] .section-title.text-start::before,
[dir="rtl"] .section-title.text-end::after {
  display: none;
}

/*** Service ***/
.service-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  transition: 0.5s;
  min-height: 200px !important;
}

.service-item:hover {
  background: var(--primary);
}

.service-item * {
  transition: 0.5s;
}

.service-item:hover * {
  color: var(--light) !important;
}

/*** Food Menu ***/
.nav-pills .nav-item .active {
  border-bottom: 2px solid var(--primary);
}

.video .btn-play {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: 18px 20px 18px 28px;
}

.video .btn-play:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  animation: pulse-border 1500ms ease-out infinite;
}

.video .btn-play:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 100px;
  height: 100px;
  background: var(--primary);
  border-radius: 50%;
  transition: all 200ms;
}

.video .btn-play img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video .btn-play span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid var(--dark);
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

#videoModal {
  z-index: 99999;
}

#videoModal .modal-dialog {
  position: relative;
  max-width: 800px;
  margin: 60px auto 0 auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 0px;
  top: -30px;
  z-index: 999;
  font-size: 30px;
  font-weight: normal;
  color: #ffffff;
  background: #000000;
  opacity: 1;
}

/*** Team ***/
.team-item {
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.08);
  height: calc(100% - 38px);
  transition: 0.5s;
}

.team-item img {
  transition: 0.5s;
}

.team-item:hover img {
  transform: scale(1.1);
}

.team-item:hover {
  height: 100%;
}

.team-item .btn {
  border-radius: 38px 38px 0 0;
}

/*** Testimonial ***/
.testimonial-carousel .owl-item .testimonial-item,
.testimonial-carousel .owl-item.center .testimonial-item * {
  transition: 0.5s;
}

.testimonial-carousel .owl-item.center .testimonial-item {
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}

.testimonial-carousel .owl-item.center .testimonial-item * {
  color: var(--light) !important;
}

.testimonial-carousel .owl-dots {
  margin-top: 24px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.testimonial-carousel .owl-dot {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  width: 15px;
  height: 15px;
  border: 1px solid #cccccc;
  border-radius: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dot.active {
  background: var(--primary);
  border-color: var(--primary);
}

/*** Footer ***/
.footer .btn.btn-social {
  margin-right: 8px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--light);
  border: 0px solid #ffffff;
  /* border-radius: 35px; */
  transition: 0.3s;
  font-size: 20px;
}

.footer .btn.btn-social:hover {
  color: var(--primary);
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #ffffff;
  font-size: 15px;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  letter-spacing: 1px;
  box-shadow: none;
}

.footer .copyright {
  padding: 25px 0;
  font-size: 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .copyright a {
  color: var(--light);
}

.footer .footer-menu a {
  margin-right: 15px;
  padding-right: 15px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .footer-menu a:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}

.food-about {
  border-radius: 6px !important;
}

.app:hover img {
  transform: scale(1.1);
}

.app {
  transition: 0.5s;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app img {
  transition: 0.5s;
}

.find-us,
.about-us {
  background-color: #c3895712;
}

.reel {
  background-image: url(../public/assets/img/pattern.png);
}

[dir="rtl"] .our-mission {
  padding-right: 30px;
}

[dir="rtl"] .counters,
[dir="rtl"] .welcome,
[dir="rtl"] .contact-section,
[dir="rtl"] .ReactModal__Overlay {
  direction: ltr !important;
}

[dir="rtl"] .why-header {
  display: flex;
}

[dir="rtl"] .menu-food-ar {
  text-align: right !important;
  margin-right: 12px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
}

[dir="rtl"] .menu-food-ar small {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif !important;
}

[dir="rtl"] .ff-secondary {
  font-family: "arabicFont", serif;
}

[dir="rtl"] .icon {
  font-family: "Font Awesome 5 Free" !important;
}
[dir="rtl"] .btn {
  font-family: "arabicFont", serif;
}

[dir="rtl"] .welcome {
  text-align: right !important;
}

[dir="rtl"] .count {
  justify-content: space-between;
  text-align: right;
}

@media (max-width: 991.98px) {
  [dir="rtl"] .welcome {
    text-align: center !important;
  }
}

[dir="rtl"] .categories {
  margin-right: 8px;
  text-align: right !important;
}

.scrollable-nav {
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
}

.scrollable-nav::-webkit-scrollbar {
  display: none;
}

.nav-item {
  flex: 0 0 auto;
}

.contact-section {
  text-align: center;
}
